@import "../styles/main_variables";

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 150px;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.header-small {
  margin-top: 0;
  margin-bottom: 15px;
  text-align: center;
}

.top-section {
  margin-top: -100px;
  min-height: 100vh;
  width: 100%;
  // min-height: calc(100vh - 100px);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  background-image: url("../../public/images/homepage_background_large.jpg");
  background-size: cover;
  border-bottom: $standardBorderWidth solid $text-color;
}

.embedded-video {
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: -1;
}

.bodyContainer, .chassisContainer {
  padding: 20px 0;
  display: flex;
  gap: 10px;
}

.chassisContainer {
  flex-direction: row-reverse;
}

.top-section-inner {
  width: 80%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-header,
.pre-order-header,
.product-header,
.product-main-inner-header,
.product-description-header,
.product-main-inner-sub,
.section-header,
.sub-header {
  text-align: center;
  font-size: 30px;
  font-style: italic;
  text-transform: uppercase;
  font-weight: 600;
}

.sub-header {
  border-top: $standardBorderWidth solid $text-color;
  font-size: 25px;
  padding-top: 20px;
  margin-top: 20px;
}

.customShopSub {
  font-size: 20px;
}

.main-description,
.sub-description {
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
}

.sub-description {
  margin-top: 15px;
}

.what-we-do {
  min-width: 250px;
  margin-top: 20px;
}

.arrow-section {
  margin-top: 20px;
  height: 70px;
  position: relative;
  width: 50px;
  display: flex;
  justify-content: center;
}

.arrow-down {
  top: 0;
  left: 8.5px;
  position: absolute;
  font-size: 35px;
  animation: down 1.5s linear 0s infinite;
}

.socialIcon {
  width: 20px;
}

@keyframes down {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(30px);
  }
}

.top-scroll-aim {
  position: absolute;
  opacity: 0;
  left: 0;
  top: -100px;
}

.visible {
  display: block;
}

.home-label {
  background-color: transparent;
  display: none;
}

.home-field:focus ~ .home-label {
  display: none;
}

.home-field {
  &::placeholder {
    color: $text-color;
    font-size: 16px;
  }
}

.form-confirmation-button {
  transform: scaleX(1);
  background-color: transparent;
  border: 0;
  border-left: $standardBorderWidth solid $text-color;
  cursor: pointer;
}

.newsletter-top {
  position: relative;
}

#newsletter-scroll {
  position: absolute;
  opacity: 0;
  top: -97px;
}

.newsletter-subscribe {
  font-size: 12px;
}

.product-section {
  width: 80%;
  max-width: 1000px;
  position: relative;
}

.product-header {
  margin: 40px 0;
}

.product-main-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-main-inner-header,
.product-description-header {
  width: 100%;
  font-size: 25px;
}

.product-main-inner-header {
  padding-top: 20px;
  border-top: $standardBorderWidth solid $text-color;
}

.product-description {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.product-description-header {
  width: 100%;
}

.vision {
  font-size: 16px;
  font-style: italic;
}

.product-image-container {
  margin: auto;
  transition: opacity .5s ease-out, transform .5s ease-out;
}

.left {
  opacity: 0;
  transform: translateX(-50px);
}

.right {
  opacity: 0;
  transform: translateX(50px);
}

.visible {
  opacity: 1;
  transform: translateX(0);
}

.product-image {
  width: 100%;
}

.chassis-image {
  max-width: 500px;
}

.product-description-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  transition: opacity .5s ease-out, transform .5s ease-out;
}

.product-description-item {
  width: 270px;
  display: flex;
  justify-content: center;
}

.view-specs {
  margin-top: 2px;
  text-decoration: none;
  text-align: center;
}

.scroll-chassis,
.scroll-body {
  position: absolute;
  opacity: 0;
  top: -95px;
}

.product-description-item-inner {
  position: relative;
  width: calc(100% - 20px);
  padding: 0;
  border: $standardBorderWidth solid transparent;
  transition: padding 0.1s;

  .product-description-text {
    font-size: 14px;
    width: 100%;
    margin-top: 0;
    text-align: center;
    height: 0;
    opacity: 0;

    transition:
      opacity 0.2s ease-out,
      height 0.3s;
  }

  &:hover {
    border-color: $text-color;
    background-color: $bright-accent;
    padding: 15px;

    .product-description-text {
      opacity: 1;
      margin-top: 10px;
      height: auto;
    }
  }
}

.product-description-sub-header {
  font-weight: 600;
  text-transform: uppercase;
}

.product-number {
  border: $standardBorderWidth solid $text-color;
  text-align: center;
  padding: 7px;
  display: flex;
  justify-content: center;

  .product-number-inner {
    font-size: 16px;
    font-weight: 600;
  }
}

.to-products {
  margin-top: 30px;
  margin-bottom: 20px;
}

.product-main-inner-sub {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 15px;
  text-transform: unset;
  text-align: left;
}

.custom-shop-description {
  display: flex;
  margin: 20px 0;
  padding-top: 20px;
  border-top: $standardBorderWidth solid $text-color;
}

.custom-shop-image {
  width: 50%;
  position: relative;
  max-width: 880px;
  margin-right: 10px;
  height: 300px;
  max-height: 487px;
  overflow: hidden;
  display: flex;
  align-items: center;
  transition: opacity .5s ease-out, transform .5s ease-out;
}

.custom-shop-text {
  margin-left: 10px;
  width: 50%;
  display: flex;
  align-items: center;
  transition: opacity .5s ease-out, transform .5s ease-out;
}

.cs-text-inner {
  font-size: 14px;
}

.custom-shop-button {
  margin-top: 30px;
}

.about-section {
  width: 80%;
}

.section-header {
  margin-top: 30px;
  padding-top: 20px;
  width: 100%;
  border-top: $standardBorderWidth solid $text-color;
}


.sub-header-2 {
  font-size: 20px;
  font-weight: 600;
  font-style: italic;
  margin-top: 20px;
  padding-top: 20px;
  text-align: center;
  width: 100%;
  border-top: 2px solid $text-color;
}

.socials-section {
  width: 80%;
  padding-bottom: 20px;
  border-bottom: $standardBorderWidth solid $text-color;
}

#social-links {
  position: relative;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.social-item {
  border: 1px solid $text-color;
  height: 40px;
  width: 40px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color $standardTransitionTime;

  &:hover {
    background-color: $bright-accent;
  }
}

.carousel-item-home {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-visible {
  opacity: 1;
  width: 100%;
  height: auto;
  position: absolute;
  transition: opacity .5s;
  border-radius: 8px;
}

.image-hidden {
  opacity: 0;
}

@media (max-width: 1000px) {
  .custom-shop-image {
    height: 240px;
  }
}

@media (max-width: 870px) {
  .custom-shop-description {
    flex-wrap: wrap;
  }

  .custom-shop-image,
  .custom-shop-text {
    width: 100%;
    margin: 0;
  }

  .custom-shop-text {
    margin-top: 20px;
    text-align: center;
  }

  .product-main-inner-sub {
    text-align: center;
  }

  .custom-shop-image {
    height: 44vw;
  }
}

@media (max-width: 1850px) {
  .top-section {
    background-image: url("../../public/images/homepage_background.jpg");
    background-size: cover;
  }
}

@media (max-width: 800px) {
  .testimonial-image {
    display: none;
  }

  .testimonial-text {
    margin-left: 75px;
  }
}

@media (max-width: 760px) {
  .news-image {
    width: 0;
  }

  .news-content {
    width: 100%;
    border: 0;
  }

  .top-section {
    background-image: url("../../public/images/homepage_background_small.jpg");
    background-position: center;
  }
}

@media (max-width: 650px) {
  .chassisContainer, .bodyContainer {
    flex-direction: column;
  }

  .product-description-item {
    width: 234px;
  }

  .product-image {
    max-width: 300px;
  }

  .chassis-image {
    max-width: 250px;
  }
}

@media (max-width: 605px) {
  .product-description-item {
    width: 100%;
  }

  #newsletter-home,
  .pp-form-group {
    left: 0;
    width: 100%;
  }

  .testimonial-text {
    margin-left: 45px;
    margin-right: 45px;
  }
}

@media (max-width: 550px) {
  .left {
    opacity: 0;
    transform: translateX(0);
  }

  .right {
    opacity: 0;
    transform: translateX(0);
  }

  .visible {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 400px) {
  .top-section-inner,
  .product-section,
  .about-section,
  .partner-program-section {
    width: 90%;
  }

  .product-description-item-inner {
    width: 100%;
  }

  .what-we-do,
  .pre-order-input {
    min-width: 220px;
  }

  .about-us-button,
  .custom-shop-button,
  .to-products,
  .partner-program-button {
    min-width: 220px;
  }
}

@media (max-height: 900px) {
  .main-header {
    margin-top: 250px;
  }
}

@media (max-height: 760px) {
  .main-header {
    margin-top: 200px;
  }
}

@media (max-height: 680px) {
  .main-header {
    margin-top: 150px;
  }
}
