@import "../styles/main_variables";

.topContainer {
  width: 80%;
  max-width: 800px;
  display: flex;
  justify-content: space-between;
  margin: 0 0 30px 0;
  padding-bottom: 30px;
  border-bottom: $standardBorderWidth solid $text-color;
  flex-wrap: wrap;
}

.leftContainer {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.aboutHeader {
  font-weight: normal;
  margin-top: 0;
  font-size: 35px;
}

.rightHeader {
  font-size: 18px;
}

.rightContainer {
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.aboutImage {
  width: 280px;
}

.galleryContainer {
  width: 80%;
  max-width: 800px;
}

.galleryHeader {
  font-style: italic;
  text-align: center;
  text-transform: uppercase;
  font-size: 25px;
}

.galleryDescription {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
}

.imageContainer {
  margin-top: 30px;
  column-count: 2;
  column-gap: 5px;
}

.galleryImage {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  width: 100%;
  height: auto;
  margin-bottom: 2px;
  transition: transform .5s ease-out, opacity .5s ease-out;
}

.imageContainer:hover .galleryImage:not(:hover) {
  filter: grayscale(100%);
  transform: scale(0.7);
}

.galleryImage:hover {
  filter: grayscale(0%);
  transform: scale(1.1);
  box-shadow: 7.1px 14.2px 14.2px hsl(0deg 0% 0% / 0.28);
}

.left {
  opacity: 0;
  transform: translateX(-50px);
}

.right {
  opacity: 0;
  transform: translateX(50px);
}

.visible {
  opacity: 1;
  transform: translateX(0);
}

@media (max-width: 1250px) {
  .imageContainer {
    column-count: 2;
  }
}

@media (max-width: 750px) {
  .topContainer {
    justify-content: center;
  }

  .leftContainer, .rightContainer {
    width: 70%;
  }

  .rightContainer {
    margin-top: 30px;
  }
}

@media (max-width: 550px) {
  .leftContainer, .rightContainer {
    width: 90%;
  }

  .left {
    opacity: 0;
    transform: translateX(0);
  }

  .right {
    opacity: 0;
    transform: translateX(0);
  }

  .visible {
    opacity: 1;
    transform: translateX(0);
  }

  .imageContainer {
    column-count: 1;
  }
}
