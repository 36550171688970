@import "../../styles/main_variables";

$letterspacing: 10px;
$navbarTop: 200px;
$distanceFromTop: 40px;

@mixin beforeAfterStandard() {
  content: "";
  position: absolute;
  background-color: $text-color;
}

.active > .nav-item > .nav-link {
  color: $bright-accent;
}

.navbar {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background-color: transparent;
  z-index: 1000;
}

.header-logo {
  position: relative;
  left: 30px;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  font-style: italic;
  pointer-events: all;
  top: 1px;
}

.logo-image {
  width: 75px;
}

.nav-list {
  display: flex;
  position: relative;
  align-items: center;
  right: 40px;

  .nav-item {
    list-style: none;
    margin: 0px 30px;
    position: relative;

    .nav-link {
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      transition: color $standardTransitionTime;

      &:hover {
        color: $bright-accent;
      }
    }
  }

  .shopping-cart-item::before {
    display: none;
  }
}

.nav-list {
  .products-item {
    position: relative;
    top: 45px;
    margin-left: 30px;
    margin-right: 0;
  }
}

.products-item::before {
  display: none;
}

.visible-products {
  display: block;
  transition: color $standardTransitionTime;
  background-color: $primary-color;
  z-index: 10;
  padding-right: 5px;
}

.visible-products:hover {
  color: $bright-accent;
}

.hidden-products {
  white-space: nowrap;
  width: 100px;
  position: relative;
  top: -20px;
  opacity: 0;
  background-color: $primary-color;
  border: 2px solid $text-color;
  padding: 5px;
  padding-top: 0;
  z-index: 0;
  transition:
    top $standardTransitionTime,
    opacity $standardTransitionTime;
  pointer-events: none;

  &::after {
    position: absolute;
    content: "";
    width: 75px;
    height: 0;
    top: -2px;
    left: 0;
    border-top: 2px solid $primary-color;
  }

  .product-type {
    display: block;
    // text-transform: uppercase;
    font-weight: 600;
    margin-top: 8px;
    transition: color $standardTransitionTime;

    &:first-of-type {
      margin-top: 3px;
    }

    &:hover {
      color: $bright-accent;
    }
  }
}

.lni-cart::before,
.lni-cart-full:before {
  // position: absolute;
  top: -5px;
  font-size: 30px;
  transition: color $standardTransitionTime;
}

#shopping-cart:hover > .lni-cart::before,
#shopping-cart:hover > .lni-cart-full::before {
  color: $bright-accent;
}

.navbar-bg {
  position: fixed;
  color: transparent;
  pointer-events: none;
  height: 100px;
  width: 100%;
  z-index: 1000;
  border-bottom: $standardBorderWidth solid $text-color;
  transition: background-color calc($standardTransitionTime / 2);
}

.bg-active {
  background-color: $primary-color;
}

@media (max-width: 870px) {
  .hamburger {
    width: 35px;
    height: 30px;
    position: fixed;
    top: $distanceFromTop - 9px;
    right: 40px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    z-index: 1;
    pointer-events: all;
  }

  .line {
    width: 100%;
    height: 3px;
    background-color: $text-color;
    transition: all 0.5s;
  }

  .change .line-1 {
    transform: rotateZ(-45deg) translate(-8px, 6px);
  }

  .change .line-2 {
    transform: translateX(100px);
  }

  .change .line-3 {
    transform: rotateZ(45deg) translate(-8px, -6px);
  }

  .change .nav-list {
    top: $navbarTop;
  }

  .change .nav-link:not(#shopping-cart) {
    display: block;
    pointer-events: all;
  }

  .navbar {
    height: 100%;
    background-color: transparent;
    display: block;
    pointer-events: none;
    transition: background-color $standardTransitionTime;
  }

  .nav-list {
    right: 0;
    top: $navbarTop + 100px;
    flex-direction: column;
    align-items: center;
    pointer-events: none;
    transition: top $standardTransitionTime ease-out;
  }

  .nav-list .nav-item {
    margin: 30px 0;
  }

  .nav-list .nav-item .nav-link {
    text-decoration: none;
    margin-right: -$letterspacing;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: $letterspacing;
  }

  .nav-link:not(#shopping-cart) {
    display: none;
  }

  .navbar .header-logo {
    position: fixed;
    width: 200px;
    left: calc(50vw - 100px);
    top: $distanceFromTop - 24px;
  }

  #shopping-cart {
    position: fixed;
    pointer-events: all;
    left: 40px;
    top: $distanceFromTop;
    font-size: 16px;
    top: 32px;
  }

  .nav-list {
    .products-item {
      top: 0;
    }
  }

  .hidden-products {
    display: none;
  }

  .products-item::before {
    display: block;
  }

  .visible-products:hover {
    color: $text-color;
  }
}

.change {
  background-color: $primary-color;
  z-index: 1000;
}

@media (max-width: 450px) {
  .hamburger {
    right: 15px;
  }

  #shopping-cart {
    left: 15px;
  }
}

@media (max-height: 700px) {
  .change .nav-list {
    top: $navbarTop - 50px;
  }
}

@media (max-height: 600px) {
  .change .nav-list {
    top: $navbarTop - 80px;
  }
}

@media (max-height: 550px) {
  .change .nav-list {
    top: $navbarTop - 100px;
  }
}

@media (max-height: 500px) {
  .change .nav-list {
    top: $navbarTop - 120px;
  }
}
