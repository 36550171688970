h3 {
  margin: 15px 0 4px 0;
}

.topic-container {
    width: 80%;
}

.topic-header {
    font-size: 20px;
}

.topic-content {
    margin-top: 10px;
    font-size: 15px;
}

@media (max-width: 400px) {
    .topic-container {
        width: 90%;
    }
}
