@import "../styles/main_variables";

h1 {
  font-size: 30px;
  padding-top: 40px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-style: italic;
  text-align: center;
}

.request-successful {
  font-size: 20px;
  color: $bright-accent;
  font-weight: 600;
  font-style: italic;
  margin-top: 10px;
}

.custom-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
}

.explanation {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.explanation-header {
  text-transform: uppercase;
  margin-top: 20px;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  padding-top: 20px;
  width: 100%;
  border-top: $standardBorderWidth solid $text-color;
}

.hidden {
  display: none;
}

.explanation-text {
  margin-top: 30px;
  text-align: center;
  font-size: 14px;
  max-width: 750px;
}

.starting-price {
  text-align: center;
  font-weight: 600;
  font-size: 15px;
  margin-top: 20px;
}

.price-main {
  font-style: italic;
  font-size: 17px;
  margin-left: 3px;
}

.price-small {
  font-style: italic;
  font-size: 15px;
}

.explanation-help {
  margin-top: 20px;
}

.questions-text {
  margin-top: 20px;
  padding-top: 20px;
  border-top: $standardBorderWidth solid $text-color;
  font-size: 13px;
  width: 100%;
  text-align: center;

  a {
    font-size: 13px;
    color: $bright-accent;
  }
}

.specification-form {
  width: 100%;
  max-width: 800px;
}

.choice {
  margin-top: 20px;
}

.choice-top {
  display: flex;
  align-items: center;
}

.choice-support {
  width: 10%;
  display: flex;
  justify-content: center;
}

.question-circle {
  cursor: pointer;
  transition: color $standardTransitionTime;
}

.question-circle:hover {
  color: $bright-accent;
}


.spec-form-outer {
  width: 90%;
}

.choice-bottom {
  margin-top: 20px;
  font-size: 14px;
}

.choice-bottom-visible {
  display: block;
}

.visible {
  display: block !important;
}

.checkbox-text {
  font-size: 16px;
}

.form-description {
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  padding-top: 15px;
  border-top: $standardBorderWidth solid $text-color;
}

.standard-input {
  width: 100%;
}

.chassis-selection,
.engine-selection {
  border-color: $soft-accent !important;
}

.options-top {
  margin-top: 10px;
}

.options-top-2 {
  margin-top: 20px;
}

.option-selection {
  width: 90%;
}

.selection-help {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chassis-selection {
  margin: 0;
  width: calc(50% - 5px);
  margin-right: 5px;
}

.chassis-selection-2 {
  margin-right: 0;
  margin-left: 5px;
}

.engine-selection {
  margin-bottom: 10px;
  margin-right: 5px;
  width: calc(33.3333% - 10px);
}

.engine-selection-3,
.engine-selection-2 {
  margin-left: 5px;
}

.engine-selection-3 {
  margin-right: 0;
}

.choice-v2 {
  width: 100%;
}

.selection-help {
  height: 50px;
}

.standard-input:checked ~ div {
  //Fragen, wie die Animation sein soll + Farben + Auto Indent

  &::before {
    content: "";
    position: absolute;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border: $standardBorderWidth solid $text-color;
  }
}

.chassis-spec-other {
  margin-top: 20px;
}

.other-spec {
  border-color: $soft-accent !important;
}

// .form-field:focus {
//     // border: $standardBorderWidth solid $text-color;
// }

.button-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0;
}

.problem-text {
  margin-bottom: 10px;
  text-align: center;
  font-size: 13px;
  padding-bottom: 10px;
  border-bottom: $standardBorderWidth solid $text-color;
  display: none;
  color: $bright-accent;
}

.problem-section {
  margin-bottom: 10px;
}

.color-choice,
.setup-choice {
  margin-top: 10px;
}

.image-header {
  margin-top: 15px;
  font-size: 14px;

  strong {
    font-size: 14px;
  }
}

input[type="file"]::file-selector-button {
  display: none;
}

#file-upload-1,
#file-upload-2 {
  margin-top: 10px;
  font-size: 14px;
  margin-bottom: 10px;
}

.image-file-upload {
  margin-top: 10px;
  display: block;
  font-size: 16px;
  font-weight: 600;
  padding: 0 18px;
  line-height: 48px;
  height: 50px;
  width: 90%;
  cursor: pointer;
  border: $standardBorderWidth solid $text-color;
  transition: color $standardTransitionTime;
}

.image-file-upload:hover {
  color: $bright-accent;
}

.feature {
  padding-bottom: 20px;
  border-bottom: $standardBorderWidth solid $text-color;
}

.feature-top {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.feature-main {
  display: flex;
  align-items: center;
  width: 90%;
}

.feature-text {
  font-size: 14px;
  font-weight: 600;
  margin-left: 20px;
  padding-right: 10px;
}

.special-req-textarea {
  width: 90%;
  height: 120px;
  background-color: transparent;
  border: $standardBorderWidth solid $soft-accent;
  font-size: 15px;
  font-weight: 600;
  padding: 15px 20px;
  resize: none;

  &:focus {
    outline: none;
  }

  &:focus::placeholder {
    color: transparent;
  }

  &::placeholder {
    font-size: 16px;
    font-weight: 700;
    color: white;
  }
}

.text-area-section {
  margin-top: 15px;
  display: flex;
  align-items: center;
  //Zweite Linie überhalb
  // padding-bottom: 20px;
  // border-bottom: $standardBorderWidth solid $text-color;
}

.master-paint {
  padding-bottom: 0;
  border-top: $standardBorderWidth solid $text-color;
  border-bottom: none;
  margin-top: 20px;

  .feature-top {
    margin-top: 10px;
  }
}

.engine-options {
  // padding-bottom: 20px;
  border-bottom: $standardBorderWidth solid $text-color;
}

.price-approximation {
  padding: 15px 0;
  margin: 20px 0;
  border-bottom: $standardBorderWidth solid $text-color;
  display: flex;
  justify-content: space-between;
}

.approximation-left,
.approximation-right {
  font-size: 16px;
}

.actual-value {
  font-size: 16px;
}

.price-small-bottom {
  font-size: 13px;
}

.email-section {
  margin-top: 35px;
  width: 100%;
}

.contact {
  color: $bright-accent;
}

@media (max-width: 400px) {
  .custom-outer {
    width: 90%;
  }

  .spec-form-outer,
  .option-selection {
    width: 85%;
  }

  .choice-support,
  .selection-help {
    width: 15%;
  }

  .image-file-upload,
  .spec-form-outer {
    width: 100%;
  }
}
