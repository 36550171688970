@import "./main_variables";

//-------------------------------------------------------------------
// Checkbox
//-------------------------------------------------------------------

//Checkbox 1
.checkbox {
    position: relative;
    input,
    svg {
        width: 30px;
        height: 30px;
        display: block;
    }
    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        position: relative;
        outline: none;
        background: $primary-color;
        border: none;
        padding: 0;
        cursor: pointer;
        border-radius: 0;
        transition: box-shadow 0.3s;
        box-shadow: inset 0 0 0 var(--s, 1px) $text-color;
        &:hover {
            --s: 2px;
            --b: $text-color;
        }
        &:checked {
            --b: $text-color;
        }
    }
    svg {
        pointer-events: none;
        fill: none;
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke: $text-color;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 30px;
        height: 30px;
        transform: scale(var(--scale, 1)) translateZ(0);
    }
    &.path {
        input {
            &:checked {
                --s: 2px;
                transition-delay: 0.4s;
                & + svg {
                    --a: 16.1 86.12;
                    --o: 102.22;
                }
            }
        }
        svg {
            stroke-dasharray: var(--a, 86.12);
            stroke-dashoffset: var(--o, 86.12);
            transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
        }
    }
    &.bounce {
        --stroke: $text-color;
        input {
            &:checked {
                --s: 11px;
                & + svg {
                    animation: bounce 0.4s linear forwards 0.2s;
                }
            }
        }
        svg {
            --scale: 0;
        }
    }
}

@supports (-webkit-touch-callout: none) {
    .checkbox svg {
         top: -30px;
    }
}

@keyframes bounce {
    50% {
        transform: scale(1.2);
    }
    75% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1);
    }
}


/* Checkbox Select */
.checkbox-selection {
    position: relative;
    height: 50px;
    float: left;
    border: 2px solid $text-color;
    box-sizing: border-box;
}

.checkbox-selection div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // line-height: 25px;
    transition: 0.5s ease;
}

.checkbox-selection input {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.checkbox-text {
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    width: 90%;
}
