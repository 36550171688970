@import "./main_variables";

*, *::before, *::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;

    // Todo: Move to appropriate place
    scroll-behavior: smooth;
    font-family: $primary-font-string, 'sans-serif';
}

body {
    color: $text-color;
}

a {
    color: inherit;
    text-decoration: none;
}

body {
    background-color: $primary-color;
}

// Scrollbar styles
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: $soft-accent;
}

::-webkit-scrollbar-thumb {
    background: $mid-accent;
}

::-webkit-scrollbar-thumb:hover {
    background: $bright-accent;
}

// Input outofill
input:-webkit-autofill {
    color: $text-color !important;
    background-color: transparent !important;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: $text-color !important; /* Change text color */
}





//-------------------------------------------------------------------
// Dropdown
//-------------------------------------------------------------------
$dropdownWidth: 100%;

.dropdown-outer,
.dropdown-item {
    float: left;
    list-style: none;
    position: relative;
    width: 100%;
    z-index: 10;
    background-color: $primary-color;

    a {
        font-weight: 600;
        // font-family: '';
        position: relative;
        color: $text-color;
        border: $standardBorderWidth solid $text-color;
        padding: 12.5px 15px 12.5px 15px;
        display: block;
        width: $dropdownWidth;
        z-index: 100;
        width: 100%;

        i::before {
            font-size: 15px;
            position: absolute;
            right: 10px;
            top: 12px;
        }
    }

    .drop-down {
        position: absolute;
        display: none;
        left: 0;
        z-index: 0;
        width: 100%;

        li {
            position: relative;
            float: none;

            a {
                border-top: none;
                width: $dropdownWidth;


                &:hover {
                    background-color: $text-color;
                    color: $primary-color;
                }
            }
        }

        li:nth-of-type(1) a {
            border-top: none;
        }
    }
}

.accent {
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: $text-color;
    position: absolute;
    transition: $standardTransitionTime ease;
}

.animate {
    width: 100%;
    transition: $standardTransitionTime ease;
}

.item:hover {
    color: $text-color;
}

