.headerContainer {
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.mainHeader {
    margin: 0;
    font-size: 30px;
    font-weight: normal;
    font-style: italic;
    text-transform: uppercase;
}

.subHeader {
    font-weight: normal;
    margin: 0;
    font-size: 16px;
}